
















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/PNController'
import routeController from '@/app/ui/controllers/RouteController'
import { Utils } from '@/app/infrastructures/misc'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import OptionBox from '@/app/ui/components/OptionBox/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import ClockIcon from '@/app/ui/assets/icon_clock.vue'
import CalendarIcon from '@/app/ui/assets/icon_calendar.vue'
import { NotificationData, PushNotification } from '@/domain/entities/PN'
import * as TypeData from '@/app/ui/components/OptionBox/interfaces'
import { platformValidationOptions } from '@/app/infrastructures/misc/Constants/pushNotification'

@Component({
  computed: {
    Utils() {
      return Utils
    },
  },
  components: {
    Badge,
    Modal,
    Button,
    EditIcon,
    LoadingOverlay,
    ClockIcon,
    CalendarIcon,
    OptionBox,
  },
})
export default class PNDetail extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  routeController = routeController
  membershipSelected: string[] = []

  created(): void {
    controller.getPNDetail(this.$route.params.id)
  }

  @Watch('pnDetail')
  onPNDetailChanged(val: { targetUsers: string }): void {
    if (val.targetUsers === controller.targetOptions[2].value) {
      routeController.getCityList()
    }
  }

  get pnDetail(): PushNotification {
    return controller.pnDetail
  }

  @Watch('pnDetail')
  onDetailChanged(val: { notificationData: NotificationData | undefined, membershipLevel: string | undefined }): void {
    if (val.notificationData) {
      val.notificationData.membershipLevel.forEach(val => {
        if (val.selected) {
          this.membershipSelected.push(String(val.levelID))
        }
      })
    } else {
      if (val.membershipLevel) {
        this.membershipSelected.push(...val.membershipLevel.split(','))
      }
    }
  }

  get membershipOptions(): TypeData.GroupOption<string, string>[] {
    let options = controller.membershipOptions

    if (this.pnDetail.notificationData) {
      options = this.pnDetail.notificationData.membershipLevel.map(val => {
        return {
          label: val.levelName,
          value: String(val.levelID),
        }
      })
    }

    return options
  }

  get isMembershipTier(): boolean {
    return (
      this.controller.pnDetail.targetUsers ===
      this.controller.targetOptions[3].value
    )
  }

  get isByCity(): boolean {
    return (
      this.controller.pnDetail.targetUsers ===
      this.controller.targetOptions[2].value
    )
  }

  get cityNames(): string {
    let finalValue = '-'

    const data = routeController.cityData
    .filter(item =>
      controller.pnDetail.cities?.includes(item.value.toString())
    )

    if (data) {
      finalValue = data.map(item => item.label).join(', ')
    }

    return finalValue
  }

  get isSelectedUser(): boolean {
    return (
      this.controller.pnDetail.targetUsers ===
      this.controller.targetOptions[0].value
    )
  }

  get osVersionText(): string | undefined {
    return this.controller.pnDetail.targetUsers ===
    this.controller.targetOptions[4].value
      ? `${this.controller.pnDetail.platformOS} ${
        platformValidationOptions.find(
          item => item.value === this.controller.pnDetail.platformValidation
        )?.label
      } ${this.controller.pnDetail.platformVersion}`
      : undefined
  }

  get linkType(): string {
    let typeValue = '-'
    if (
      this.controller.pnDetail.targetUrl &&
      this.controller.pnDetail.ctaName
    ) {
      typeValue = 'URL with CTA'
    }

    if (this.controller.pnDetail.targetUrl) {
      typeValue = 'URL'
    }

    return typeValue
  }

  get publishDate(): string {
    return Utils.formatDate(this.controller.pnDetail.publishDate || '', 'DD/MM/YY')
  }

  get publishTime(): string {
    return Utils.formatDate(this.controller.pnDetail.publishDate || '', 'HH:mm:ss')
  }
}
